@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.succes {
  background-color: rgb(14, 119, 14);
}

.succes-animation {
  animation: succes-pulse 2s infinite;
}

.danger {
  background-color: #CA0B00;
}

.danger-animation {
  animation: danger-pulse 2s infinite;
}

.custom-modal {
  position: relative;
  width: 350px;
  min-height: 250px;
  background-color: #fff;
  border-radius: 30px;
  margin: 40px 10px;
}

.custom-modal .content {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
}

.custom-modal .content .type {
  font-size: 23px;
  color: #CA0B00;
  font-weight: bold;
}

.custom-modal .content .message-type {
  font-size: 24px;
  color: #000;
}

.custom-modal .border-bottom {
  position: absolute;
  width: 300px;
  height: 20px;
  border-radius: 0 0 30px 30px;
  bottom: -20px;
  margin: 0 25px;
}

.custom-modal .icon-top {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: -30px;
  margin: 0 125px;
  font-size: 30px;
  color: #fff;
  line-height: 100px;
  text-align: center;
}

.type{
  
}
@keyframes succes-pulse {
  0% {
      box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, .2);
  }

  50% {
      box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, .4);
  }

  100% {
      box-shadow: 0px 0px 30px 20px rgba(75, 181, 67, .2);
  }
}

@keyframes danger-pulse {
  0% {
      box-shadow: 0px 0px 30px 20px rgba(202, 11, 0, .2);
  }

  50% {
      box-shadow: 0px 0px 30px 20px rgba(202, 11, 0, .4);
  }

  100% {
      box-shadow: 0px 0px 30px 20px rgba(202, 11, 0, .2);
  }
}


.page-wrapper {
  height: 90vh;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

body {
  margin: 0;
  font-family: 'Roboto';
}

@media only screen and (max-width: 800px) {
  .page-wrapper {
      flex-direction: column;
  }
}

.danger-animation {
  display: flex;
  justify-content: center; /* horizontally center the content */
  align-items: center; /* vertically center the content */
}



